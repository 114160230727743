import React from 'react'
import classNames from 'classnames'
import {getOccurrences} from '@wix/wix-events-commons-statics'
import {useTranslation} from 'react-i18next'
import {DH} from '../../../constants/data-hooks'
import cs from '../../classnames.scss'
import {usePageState} from '../../runtime-context/context'
import s from './recurring-dates-count.scss'

export const RecurringDatesCount = () => {
  const count = usePageState((state) => getOccurrences(state.event).length)
  const {t} = useTranslation()

  return (
    <div data-hook={DH.eventDetailsFullLocation} className={classNames(s.root, cs.evTextFont, cs.evTextColor)}>
      {t('recurring.moreDatesLabel', {count})}
    </div>
  )
}
