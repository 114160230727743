import {withTranslation} from 'react-i18next'
import {DetailsPageAppProps} from '../../../../app/interfaces'
import {getButtonStyle} from '../../../../../selectors/settings'
import {connect} from '../../../../runtime-context/context'
import {UpdatedIndicationRuntimeProps, UpdatedIndicationOwnProps} from './interfaces'
import {UpdatedIndication as Presentation} from './updated-indication'

export const mapRuntimeToProps = ({state}: DetailsPageAppProps): UpdatedIndicationRuntimeProps => ({
  buttonStyle: getButtonStyle(state.component.settings),
})

export const UpdatedIndication = connect<UpdatedIndicationOwnProps, UpdatedIndicationRuntimeProps>(mapRuntimeToProps)(
  withTranslation()(Presentation),
)
