import {
  isRegistrationClosedVisible,
  getExternalLink,
  hasImage,
  isTicketed,
  isExternal,
} from '@wix/wix-events-commons-statics'
import {memberRsvpExists} from '../../../selectors/member-rsvp'
import {getButtonStyle, getTexts, isRsvpButtonVisible} from '../../../selectors/settings'
import {DetailsPageAppProps} from '../../app/interfaces'
import {connect} from '../../runtime-context/context'
import {isMobile} from '../../../../commons/selectors/environment'
import {RegistrationButtonOwnProps, RegistrationButtonRuntimeProps} from './interfaces'
import {RegistrationButton as Presentation} from './registration-button'

const mapRuntime = ({state, actions}: DetailsPageAppProps): RegistrationButtonRuntimeProps => {
  const event = state.event
  const componentSettings = state.component.settings
  const ticketedEvent = isTicketed(event)

  return {
    registrationButtonVisible: isRsvpButtonVisible(state),
    registrationClosed: isRegistrationClosedVisible(event),
    memberRsvpExists: memberRsvpExists(state),
    ticketedEvent,
    rsvpButtonText: getTexts(componentSettings).rsvpButtonText,
    buttonStyle: getButtonStyle(componentSettings),
    imageVisible: hasImage(event),
    navigateToMainPage: actions.navigateToMainPage,
    isMobile: isMobile(state),
    ticketsUnavailable: ticketedEvent && !state.tickets.length,
    url: getExternalLink(event),
    isExternalLink: isExternal(event),
  }
}

export const RegistrationButton = connect<RegistrationButtonOwnProps, RegistrationButtonRuntimeProps>(mapRuntime)(
  Presentation,
)
export * from './interfaces'
