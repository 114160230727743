import {getEventId, isDraft, isTicketed} from '@wix/wix-events-commons-statics'
import {callAPI, createActions} from '../../commons/actions/data-action-helper'
import {updateDates} from '../../commons/actions/dates'
import {DETAILS_ROUTE} from '../../commons/constants/navigation'
import {isSchedulePageInstalled} from '../../commons/utils/wix-code-api'
import {isDemoEvent} from '../selectors/event'
import {GetState, StoreExtraArgs} from '../types'
import {getDraftToken} from '../utils/query'
import {getCheckoutOptions} from './checkout-options'
import {navigate} from './navigation'
import {getPlanList} from './paid-plans'
import {getMemberRsvp} from './rsvp'

export const SHARE_EVENT = 'SHARE_EVENT'
export const CHANGE_EVENT = 'CHANGE_EVENT'
export const GET_EVENT = createActions('GET_EVENT')
export const GET_MEMBERS = createActions('GET_MEMBERS')

export const changeEvent =
  (slug: string) =>
  async (dispatch: Function, getState: GetState, {wixCodeApi, serverApi}: StoreExtraArgs) => {
    const state = getState()
    const responsive = !!state.component.settings?.responsive
    const draftPreviewToken = getDraftToken(wixCodeApi)
    const schedulePageInstalled = await isSchedulePageInstalled(wixCodeApi)

    const {dates, event, tickets, policies, schedule} = await serverApi.getData({
      slug,
      responsive,
      schedulePageInstalled,
      draftPreviewToken,
    })
    await dispatch(updateDates(dates))
    await dispatch({type: CHANGE_EVENT, payload: {event, tickets, policies, schedule}})

    dispatch(getMemberRsvp())
    dispatch(getExtraEventData(event))

    dispatch(navigate(DETAILS_ROUTE.DETAILS))
  }

export const getExtraEventData = (event: wix.events.Event) => (dispatch: Function, getState: GetState) => {
  const state = getState()
  if (event && !isDraft(event)) {
    dispatch(getMembers())

    if (state.paidPlansEnabled) {
      dispatch(getPlanList())
    }
    if (isTicketed(event)) {
      dispatch(getCheckoutOptions())
    }
  }
}

export const getEvent = () => (dispatch: Function, getState: GetState) => {
  const state = getState()
  if (!isDemoEvent(state)) {
    return dispatch(callAPI(GET_EVENT, getEventId(state.event)))
  }
}

export const getMembers = () => (dispatch: Function, getState: GetState) => {
  const state = getState()
  if (state.membersAreaEnabled && getEventId(state.event) && !isDemoEvent(state)) {
    return dispatch(callAPI(GET_MEMBERS, getEventId(state.event)))
  }
}

export const shareEvent = (buttonName: string, origin: string) => ({type: SHARE_EVENT, payload: {buttonName, origin}})
