import {
  isRegistrationClosedVisible,
  getRestrictedTo,
  isRegistrationClosed,
  getAboutText,
  hasGuestsGoing as commonsHasGuestsGoing,
  isRsvp,
  isLocationTbd,
  getEventDescription,
  isTicketed,
  isEventLocationOnline,
} from '@wix/wix-events-commons-statics'
import {VisitorType} from '@wix/events-types'
import {isEditor} from '../../commons/selectors/environment'
import {State, ThankYouMessageState} from '../types'
import {memberRsvpExists} from './member-rsvp'
import {getSelectedTicketsQuantity} from './selected-tickets'
import {isAboutSectionEnabled, isDescriptionEnabled, isMapEnabled, isRsvpButtonVisible} from './settings'

const DEFAULT_GUEST_COUNT = 3
export const getDefaultGuestTotal = (isRsvpEvent: boolean): TotalEventGuests =>
  isRsvpEvent ? {YES: DEFAULT_GUEST_COUNT} : DEFAULT_GUEST_COUNT

export const getFormMessages = (event: wix.events.Event, state: ThankYouMessageState) => {
  const {negativeMessages, waitlistMessages, positiveMessages} = event.form.messages.rsvp
  switch (state) {
    case 'no':
      return negativeMessages
    case 'waiting':
      return waitlistMessages
    default:
      return positiveMessages
  }
}

export const hasGuestsGoing = (state: State) => {
  const totalGuests = state.event?.totalGuests ?? 0
  const guestsGoing = commonsHasGuestsGoing(totalGuests, isRsvp(state.event))

  if (typeof totalGuests === 'object') {
    return Object.keys(totalGuests)?.length > 0 && guestsGoing
  }

  return Boolean(totalGuests) && guestsGoing
}

export const getTotalGuests = (state: State): TotalEventGuests => {
  const isRsvpEvent = isRsvp(state.event)
  const areGuestsGoing = hasGuestsGoing(state)
  if (!areGuestsGoing && isEditor(state)) {
    return getDefaultGuestTotal(isRsvpEvent)
  }
  return state.event.totalGuests
}

export const getMembers = (state: State): Member[] => state.event.members || []

export const isAboutSectionVisible = (state: State) =>
  isAboutSectionEnabled(state.component.settings) && (isEditor(state) || !!getAboutText(state.event))

export const isMapVisible = (state: State) =>
  isMapEnabled(state.component.settings) && !isLocationTbd(state.event) && !isEventLocationOnline(state.event)

export const isSecondRegistrationButtonVisible = (state: State) => {
  const event = state.event
  const rsvpButtonVisible = isRsvpButtonVisible(state)
  const registrationClosed = isRegistrationClosed(event)
  return (
    (!rsvpButtonVisible && isTicketed(event) && !registrationClosed) ||
    (rsvpButtonVisible && isAboutSectionVisible(state) && !registrationClosed && !memberRsvpExists(state))
  )
}

export const isDemoEvent = (state: State) => Boolean(Object.keys(state.demoEvents).length)

export const isDescriptionVisible = (state: State) =>
  isDescriptionEnabled(state.component.settings) && !!getEventDescription(state.event)

export const isCheckoutButtonDisabled = (state: State) =>
  isRegistrationClosedVisible(state.event) || !getSelectedTicketsQuantity(state.selectedTickets)

export const isRestrictedTo = (state: State, visitorType: VisitorType) =>
  state.membersAreaEnabled && getRestrictedTo(state.event) === visitorType
